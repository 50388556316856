<template>
  <div class="warehouse-page">
    <el-form
      :inline="true"
      :model="searchForm"
      class="bysearchForm"
      ref="dataForm"
      @keyup.enter.native="getDataList()"
    >
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-input
            v-model="searchForm.shopName"
            placeholder="店铺名"
            clearable
          />
        </el-form-item>
        <el-form-item class="typeInput">
          <el-select
            v-model="searchForm.status"
            clearable
            placeholder="是否使用"
          >
            <el-option label="使用中" value="1" />
            <el-option label="禁用中" value="0" />
          </el-select>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList()">查询</el-button>
          <el-button @click="$dialog('shop')" type="primary"
            >新增店铺</el-button
          >
        </el-form-item>
      </div>
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-input
            v-model="searchForm.freezerName"
            placeholder="套餐名"
            clearable
          />
        </el-form-item>
        <el-form-item class="typeInput">
          <el-select
            v-model="searchForm.status"
            clearable
            placeholder="是否使用"
          >
            <el-option label="使用中" value="1" />
            <el-option label="禁用中" value="0" />
          </el-select>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList()">查询</el-button>
          <el-button @click="$dialog('addOrUpdate')" type="primary"
            >新增套餐</el-button
          >
          <el-button @click="$dialog('cate')" type="info">套餐设定</el-button>
        </el-form-item>
      </div>
    </el-form>
    <div class="main-container">
      <div class="aside-wrap" style="width: 40%">
        <el-table
          height="calc(100vh - 210px)"
          :data="dataList"
          ref="dataList"
          border
          @selection-change="$selectionChange($event, 'dataList')"
          @row-click="$clickRow($event, 'dataList', getDetailList($event))"
          @select="$select(arguments, 'dataList')"
          @select-all="$clearSelection('dataList')"
        >
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50"
          />
          <el-table-column
            prop="name"
            header-align="center"
            align="center"
            label="店铺名"
          />
          <el-table-column
            prop="isUse"
            header-align="center"
            align="center"
            label="是否使用"
          >
            <template slot-scope="scope">
              <el-tag size="mini" v-if="scope.row.isUse === 1">使用中</el-tag>
              <el-tag size="mini" v-else type="danger">禁用</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="isUse"
            header-align="center"
            align="center"
            label="排序号"
          />
        </el-table>
        <el-pagination
          background
          @size-change="$sizeChange($event, 'dataList')"
          @current-change="$currentChange($event, 'dataList')"
          :current-page="dataListIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="dataListSize"
          :total="dataListCount"
          layout="total, sizes, prev, pager, next"
        />
      </div>
      <div class="main-wrap">
        <el-table
          :data="detailList"
          ref="detailList"
          border
          stripe
          v-loading="detailListLoading"
          @row-click="$clickRow($event, 'detailList')"
          @selection-change="$selectionChange($event, 'detailList')"
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50"
          />
          <el-table-column
            prop="freezerName"
            header-align="center"
            align="center"
            label="餐品名"
          />
          <el-table-column
            prop="mobile"
            header-align="center"
            align="center"
            label="价格"
          />
          <el-table-column
            prop="isUse"
            header-align="center"
            align="center"
            label="是否使用"
          >
            <template slot-scope="scope">
              <el-tag size="mini" v-if="scope.row.isUse === 1">使用中</el-tag>
              <el-tag size="mini" v-else type="danger">禁用</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="isUse"
            header-align="center"
            align="center"
            label="排序号"
          />
          <el-table-column
            prop="createTime"
            header-align="center"
            align="center"
            label="创建时间"
          />
        </el-table>
        <el-pagination
          background
          @size-change="$sizeChange($event, 'detailList')"
          @current-change="$currentChange($event, 'detailList')"
          :current-page="detailListIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="detailListSize"
          :total="detailListCount"
          layout="total, sizes, prev, pager, next, jumper"
        />
      </div>
    </div>
    <!-- 弹窗，餐品类别新增或编辑 -->
    <shop-add-or-update v-if="shopVisible" ref="shop" />
    <!-- 弹窗，餐品 新增或者编辑 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" />
    <!-- 弹窗，围餐的内容设定 -->
    <cate-add-or-update v-if="cateVisible" ref="cate" />
  </div>
</template>

<script>
import { threeListMixin, normal } from '@/mixins';
import shopAddOrUpdate from './shop-add-or-update';
import addOrUpdate from './surroundingList-add-or-update';
import cateAddOrUpdate from './surroundingList-change';
export default {
  components: { shopAddOrUpdate, addOrUpdate, cateAddOrUpdate },
  mixins: [normal, threeListMixin],
  data() {
    return {
      searchForm: {
        name: '',
        mobile: '',
        idCard: '',
        shopName: null,
      },
      addOrUpdateVisible: false,
      shopVisible: false,
      cateVisible: false,
    };
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.$api({
        url: '/delivery/deliveryuser/combo',
        after: (data) => {
          if (data && data.code === 0) {
            if (data.list?.length) {
              this.dataList = data.list;
            } else {
              this.dataList = [];
            }
          } else {
            this.dataList = [];
          }
        },
      });
    },
    // 获取数据列表
    getDetailList(row) {
      this.$api({
        url: '/delivery/deliverytask/list',
        params: {
          page: this.detailListIndex,
          limit: this.detailListSize,
          freezerName: this.searchForm.freezerName,
          status: this.searchForm.status,
        },
        after: (data) => {
          if (data && data.code === 0) {
            if (data.page && data.page.records?.length) {
              this.detailList = data.page.records;
              this.detailListCount = data.page.total;
            } else {
              this.detailList = [];
              this.detailListCount = 0;
            }
          } else {
            this.detailList = [];
            this.detailListCount = 0;
          }
        },
      });
    },
  },
};
</script>

<style lang="scss">
/* element滚动条组件 隐藏水平滚动条 */
.sidebar-wrapper .el-scrollbar__wrap {
  overflow-x: hidden;
}
.is-horizontal {
  display: none;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
